import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function usePendingConfirmationsList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { invokeFunction, updateItem } = realmConnection()
  const { role = null, worker_id = null } = store.state?.userStore?.userData
  const pendingConfirmations = ref([])
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
  const collection = 'confirmation'

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'date', label: i18n.t('message.tableHeader.date') },
      { key: 'workerName.name', label: i18n.t('message.tableHeader.worker'), sortable: true },
      { key: 'processName.name', label: i18n.t('message.tableHeader.process'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const totalPendingConfirmations = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const defaultSortBy = 'date'
  const sortBy = ref(defaultSortBy)
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchPendingConfirmations = async () => {
    isLoading.value = true

    try {
      const input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        search: searchQuery.value,
        sortBy: sortBy.value || defaultSortBy,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        worker_id: (role !== 'admin') ? worker_id.$oid : null,
      }
    
      const items = await invokeFunction({ name: 'pendingConfirmationsQueryWithOffset', arg: input })

      pendingConfirmations.value = items?.pendingConfirmations?.map(e => ({
        ...e,
        date: e.date ? new Date(e.date).toLocaleDateString(`${default_language || 'en'}-US`) : ''
      })) || []

      if (currentPage.value === 1) totalPendingConfirmations.value = items?.totalRecords || 0
  } catch (error) {
    console.log(error)
    handleError({ error, defaultMessage: i18n.t('message.err_pending_confirmations') })
  } finally {
    isLoading.value = false
  }
  }

  const deletePendingConfirmation = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true } }

      await updateItem({ collection, query, action })

      fetchPendingConfirmations()
      showSuccessMessage(i18n.t('message.pending_confirmation_remove'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.pending_confirmation_remove_error'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (pendingConfirmations.value.length ? 1 : 0)
    const to = pendingConfirmations.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalPendingConfirmations.value })
  })

  watch([currentPage, perPage], () => {
    fetchPendingConfirmations()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchPendingConfirmations()
  })

  return {
    fetchPendingConfirmations,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    totalPendingConfirmations,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    pendingConfirmations,
    deletePendingConfirmation,
    isLoading,
  }
}
